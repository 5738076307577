import request from '@/util/request';
import wx from 'weixin-js-sdk';

export default function wxShare() {
  const wxShareHandler = (wxParams = {}) => {
    if (!wx) return;

    const url = wxParams.url || window.location.href;

    request({
      url: '/api/sys/share/getShareAccessToken',
      params: { url: encodeURIComponent(url) },
      method: 'get',
    }).then((res) => {
      wx.config({
        debug: false,
        appId: res.result.appId,
        timestamp: res.result.timestamp,
        nonceStr: res.result.nonceStr,
        signature: res.result.signature,
        jsApiList: ['onMenuShareTimeline', 'onMenuShareAppMessage'],
      });

      const defaultIcon =
        'https://yxzapp-dev.oss-cn-shanghai.aliyuncs.com/191598693566_.pic_hd.jpg';

      wx.onMenuShareAppMessage({
        title: wxParams.title || '易校招',
        link: url,
        imgUrl: wxParams.icon || defaultIcon,
        desc: wxParams.desc || '',
      });

      wx.onMenuShareTimeline({
        title: wxParams.title || '易校招',
        link: url,
        imgUrl: wxParams.icon2 || wxParams.icon || defaultIcon,
        desc: wxParams.desc2 || wxParams.desc || '',
      });
    });
  };

  return {
    wxShareHandler,
  };
}
